<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.moments.overview')">
    <CCol cols="12" lg="12">
      <loyaltyMoments :platformPermissions="platformPermissions" :platformRoles="platformRoles" :modules="modules" :showHeader="true"/>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import loyaltyMoments from '@/components/loyalty/loyaltyMoments.vue'

export default {
  name: 'Moments',
  components: {
    loadingSpinner,
    noPermission,
    loyaltyMoments
  },
  data() {
    return {
      modules: {
        auto_moments: 0
      },
      platformPermissions: [],
      platformPermissionsLoaded: false,
      platformRoles: []
    }
  },
  methods: {
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    }                    
  },
  mounted: function() {  
    this.getPlatformRoles();
    this.getPlatformPermissions();
    this.checkModules();
  }
}
</script>